'use strict';
const utils = require('../components/utils');

const hideLoginModal = () => {
    $('.login-modal').remove();
};

const launchLoginModal = (loginUrl, options) => {
    if (!loginUrl) {
        return;
    }

    let $modal;
    let removeLoyaltyUrl;

    const defaultStrings = {
        logIn: 'Log In',
        forgotPassword: 'Forgot Password',
        leavingLogin: 'Leaving Log-In'
    };
    let modalResources = defaultStrings;
    if (window.resources && window.resources.loginModal) {
        modalResources = $.extend({}, defaultStrings, window.resources.loginModal);
        removeLoyaltyUrl = modalResources.removeLoyaltyUrl;
    }

    $.get(loginUrl).then((data) => {
        const defaults = {
            title: modalResources.logIn,
            modalSizeClass: 'modal-md',
            customClasses: 'login-modal'
        };
        const settings = $.extend({}, defaults, options);
        let modalMarkup = utils.createModalMarkup(data, settings);
        $modal = $(modalMarkup).modal('show');

        $modal
            .on('hidden.bs.modal', () => {
                hideLoginModal();
            })
            .on('shown.bs.modal', () => {
                if (removeLoyaltyUrl) {
                    // replace the close button with a toggle button
                    $modal.find('.modal-header .close').hide().after(`
                        <a class="btn btn-icon close js-login-modal-accordion-control" href="#pblRemoveRewardsConfirmation" role="button" aria-controls="pblRemoveRewardsConfirmation" id="pblLoginCloseModalLink"><svg class="svgicon"><use href="#close"></use></svg></a>
                    `);

                    $('#removeRewardsBtn').on('click', (e) => {
                        e.preventDefault();
                        $.spinner().start();
                        $.ajax({
                            url: removeLoyaltyUrl,
                            method: 'POST'
                        }).done((data) => { // eslint-disable-line no-shadow
                            if (data.success) {
                                // rewards have been removed.
                                location.reload();
                            } else {
                                // something went wrong
                                $.spinner().stop();
                                $.modal({
                                    content: data.errorMessage,
                                    customClasses: 'modal--error'
                                });
                            }
                        }).fail((jqXHR) => {
                            $.spinner().stop();
                            // show an error modal
                            $.modal({
                                title: `Error ${jqXHR.status}`,
                                content: '<p>Something went wrong. Please reload the page and try again.</p><p>If the issue persists, please call Customer Care.</p>',
                                customClasses: 'modal--error'
                            });
                        });
                    });
                }

                $('.js-login-modal-accordion-control').on('click', function (e) {
                    e.preventDefault();
                    const $link = $(this);
                    const $target = $($link.attr('href') || $link.data('target'));
                    const $container = $target.closest('.js-login-modal-accordion');
                    // toggle accordion content
                    $container.find('.js-login-modal-accordion-content').slideUp('fast');
                    $target.stop().slideDown('fast');
                });

                $('#forgotPasswordModalLink').on('click', (e) => {
                    e.preventDefault();
                    $('.modal-title').text(modalResources.forgotPassword);
                    var emailId = $(e.currentTarget).closest('form').find('input[name=loginEmail]').val();
                    if (emailId && emailId.length) {
                        $('#reset-password-email').val(emailId);
                    }
                });

                $('#loginModalLink, #leavingLoginBtn').on('click', (e) => {
                    e.preventDefault();
                    $('.modal-title').text(modalResources.logIn);
                });

                $('#pblLoginCloseModalLink').on('click', (e) => {
                    e.preventDefault();
                    $('.modal-title').text(modalResources.leavingLogin);
                });

                $('[data-toggle=tooltip]').tooltip();

                let $recaptchaBtn = $modal.find('form .btn-recaptcha');
                /* global registerButtonAsRecaptchaTrigger */
                registerButtonAsRecaptchaTrigger($recaptchaBtn);

                $modal.find('form').trigger('form:created');
            });
    });
};

module.exports = {
    initLoginModal: () => {
        $('body')
            .off('click.loginmodal', '.js-login-modal')
            .on('click.loginmodal', '.js-login-modal', (e) => {
                let $this = $(e.currentTarget);
                e.preventDefault();

                launchLoginModal($this.attr('href'));
            });
    },
    launchLoginModal: launchLoginModal
};

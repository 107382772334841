'use strict';

/**
 * Display error messages and highlight form fields with errors.
 * @param {*} formElem - a string selector for the form or the form element which contains the fields
 * @param {Object} fieldErrors - the fields with errors
 * @param {number} index - index of current form, default is 0
 */
function loadFormErrors(formElem, fieldErrors, index) { // eslint-disable-line
    // Display error messages and highlight form fields with errors.
    var currIndex = index || 0;
    let ogformElem = formElem;
    let isInvalidEmail = false;
    $.each(fieldErrors, function (fieldName) {
        if (fieldName === 'dwfrm_shipping_upsLocator_selectedAddress_email_emailAddress' || fieldName === 'email') {
            formElem = '.js-email-form'; // eslint-disable-line no-param-reassign
            isInvalidEmail = true;
        } else {
            formElem = ogformElem; // eslint-disable-line no-param-reassign
        }
        $(formElem).find('*[name*=' + fieldName + ']').eq(currIndex)
            .addClass('is-invalid')
            .siblings('.invalid-feedback')
            .html(fieldErrors[fieldName])
            .closest('.form-group')
            .addClass('is-invalid');
    });
    // Animate to top of form that has errors
    let headerOffset = $('.js-site-header').outerHeight() + 16;
    $(isInvalidEmail ? '.js-email-form' : ogformElem).find('.is-invalid').first().scrollTo(headerOffset);
}

/**
 * Clear the form errors.
 * @param {string} parentSelector - the parent form selector.
 */
function clearPreviousErrors(parentSelector) {
    $(parentSelector).find('.is-invalid').removeClass('is-invalid');
    $('.error-message').hide();
}

module.exports = {
    loadFormErrors: loadFormErrors,
    clearPreviousErrors: clearPreviousErrors
};

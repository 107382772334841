'use strict';

// TODO: https://columbia.atlassian.net/browse/GSD-16533 Update URL functions to use the JS URL API
/**
 * Append a parameter to a url
 * @param {string} url - a url
 * @param {string} name - key
 * @param {string} value - value
 * @return {string} url with param appended
 */
function appendParamToURL(url, name, value) {
    var c = '?';
    if (url.indexOf(c) !== -1) {
        c = '&';
    }
    return url + c + name + '=' + encodeURIComponent(value);
}

/**
 * Helper function for getParamsFromURL. Split a url string into key/value pairs.
 * @param {string} str - url parameter string
 * @return {Object} object with params split into key/value pairs
 */
function keyValueArray(str) {
    var kvp = str.split('&');
    var x;
    var k;
    var v;
    var output = {};

    for (var i = kvp.length - 1; i >= 0; i--) {
        if (kvp[i].length) { // No empty strings...
            x = kvp[i].split('=');
            k = x[0];
            v = '';
            if (x.length > 1) {
                v = x[1];
            }
            output[k] = v;
        }
    }

    return output;
}

/**
 * Split a url string into key/value pairs.
 * @param {string} url - url parameter string
 * @return {Object} object with params split into key/value pairs
 */
function getParamsFromURL(url) {
    var arr = url.split('?');
    var search = '';
    var searchHash = '';
    var postHash = false;

    if (arr.length > 1) {
        var arrHash = arr[1].split('#');
        search = arrHash[0];

        if (arrHash.length > 1) {
            postHash = true;
            searchHash = arrHash[1];
        }
    }

    var output = {};
    if (search.length) {
        output = keyValueArray(search);
    }
    if (postHash) {
        if (searchHash.length) {
            var output2 = keyValueArray(searchHash);
            Object.keys(output2).forEach(function (prop) {
                output[prop] = output2[prop]; // Merge them...
            });
        }
    }

    return output;
}

/**
 * Update an existing url param
 * https://stackoverflow.com/questions/1090948/change-url-parameters
 * @param {string} url - Url to be updated
 * @param {string} param - param to be updated
 * @param {string} paramVal - new parameter value
 * @returns {string} - updated url
 */
function updateURLParam(url, param, paramVal) {
    var newAdditionalURL = '';
    var tempArray = url.split('?');
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = '';
    if (additionalURL) {
        tempArray = additionalURL.split('&');
        for (var i = 0; i < tempArray.length; i++) {
            if (tempArray[i].split('=')[0] !== param) {
                newAdditionalURL += temp + tempArray[i];
                temp = '&';
            }
        }
    }

    var rowsText = temp + '' + param + '=' + paramVal;
    return baseURL + '?' + newAdditionalURL + rowsText;
}

/**
 * Turn the URL query string into a JSON object to make it easier to add/update/delete key/value pairs.
 * @param {string} url the URL string from which to get the querystring. Null or empty string to use the location.href.
 * @returns {obejct} the query string as an object.
 */
function queryStringToObject(url) {
    var search;
    if (!url) {
        // get the query string from window.location.search
        search = location.search.substring(1); // exclude the leading ?
    } else {
        // find the query string in the given url
        var startIndex = url.indexOf('?') + 1;
        var endIndex = url.indexOf('#');
        endIndex = endIndex >= startIndex ? endIndex : url.length;
        // examples:
        //   'key=val#hash', '/?key=val#hash' => 'key=val'
        //   '?key#hash' => 'key'
        search = url.substring(startIndex, endIndex);
        if (startIndex === 0 && search.indexOf('=') === -1) {
            // this string doesn't look like a query string (no '?' and no '=')
            // examples: 'key#hash', '/#hash'
            search = '';
        }
    }

    var result = {};
    if (search) {
        var pairs = search.split('&');
        pairs.forEach(function (pair) {
            var nameVal = pair.split('=');
            result[nameVal[0]] = decodeURIComponent(nameVal[1] || '');
        });
    }

    return JSON.parse(JSON.stringify(result));
}

/**
 * Convert an object into a serialized string; the opposite of queryStringToObject().
 * @param {Object} obj The object to be serialized into a string
 * @param {string} urlString An optional url into which to insert the querystring. Any existing query string will be replaced.
 * @returns {string} The resulting query or URL string.
 */
function objectToQueryString(obj, urlString) {
    var url = urlString || '';
    var path = '';
    var hash = '';
    if (url) {
        var qsIndex = url.indexOf('?');
        var hashIndex = url.indexOf('#');
        var index;
        if (hashIndex > -1) {
            index = hashIndex;
            hash = url.substring(hashIndex);
        }
        if (qsIndex > -1) {
            index = qsIndex;
        }
        path = url.substring(0, index); // if index is undefined, path is the entire string.
    }
    return path + '?' + $.param(obj) + hash;
}

/**
 * Update a URL with a key/value pair. This will insert or update a pair.
 * @param {string} url The url to be updated.
 * @param {string} name The key name to use in the query string.
 * @param {string} val The value of the key to be set.
 * @returns {string} The updated URL.
 */
function setUrlKeyValue(url, name, val) {
    var qs = queryStringToObject(url);
    qs[name] = encodeURIComponent(val);
    return objectToQueryString(qs, url);
}

/**
 * Update a URL with multiple key/value pairs. This will insert new or update existing keys.
 * @param {string} url The url to be updated.
 * @param {Object} obj an object of name/value pairs to be inserted/updated in the url query string.
 * @returns {string} The updated URL.
 */
function setUrlData(url, obj) {
    var qs = queryStringToObject(url);
    var mergedQs = $.extend({}, qs, obj);
    return objectToQueryString(mergedQs, url);
}

/**
 * DecodeURIComponent cannot be used directly to parse query parameters from a URL. It needs a bit of preparation.
 * reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent
 * @param {string} val The query param value to be decoded.
 * @returns {string} The decoded query param value.
 */
function decodeQueryParam(val) {
    return decodeURIComponent(val.replace(/\+/g, ' '));
}

/**
 * Remove the queryparam from the url
 * @param {string} queryparam queryparam to be removed
 * @param {string} urlString url to be manipulated
 * @returns {URL} new url without provided queryparam
 */
function removeQueryParam(queryparam, urlString) {
    var url = new URL(urlString);
    url.searchParams.delete(queryparam);
    return url;
}

/**
 * Remove the queryparam from the url, and replace the state
 * @param {string} queryparam queryparam to be removed
 */
function removeQueryParamFromCurrentUrl(queryparam) {
    var url = removeQueryParam(queryparam, window.location.href);
    history.replaceState(null, '', url);
}

/**
 * Returns an array with values that exist in both arrays
 * @param {array} a1 - Array
 * @param {array} a2 - Array
 * @return {array} - Values that appear in both arrays
 */
function arrayIntersection(a1, a2) {
    var intersect = $.map(a1,
        function (v) {
            return $.inArray(v, a2) < 0 ? null : v;
        }
    );
    return intersect;
}

/**
 * Creates and returns the markup required to display a modal.
 * The calling script should then use the bootstrap $.modal() method to display the modal.
 * Example 1:
 *      var modalMarkup = createModalMarkup('<p>Hello World</p>', {title: 'Greeting'});
 *      var $modal = $(modalMarkup).modal();
 * Example 2:
 *      var $modal = $(createModalMarkup('')); // create empty modal
 *      $modal.find('.modal-body').html(data); // inject content into the returned markup
 *      $modal.modal();
 * @param {string} content The content to put in the modal. Pass empty string to just get the modal markup.
 * @param {Object} options Additional settings.
 * @return {string} The completed markup.
 */
function createModalMarkup(content = '', options = {}) {
    /* Bootstrap Modal documentation: https://getbootstrap.com/docs/4.0/components/modal/ */
    const defaults = {
        title: '',
        showCloseBtn: true,
        enableLayeredDrawers: false,
        modalSizeClass: 'modal-lg', // possible values: modal-sm, modal-md (or empty string), modal-lg, modal-fluid
        centeredClass: 'modal-dialog-centered', // pass empty string to top-align the modal
        customClasses: '', // additional classes to be added to the modal
        modalId: '', // Custom id for the modal
        buttons: [
            // example markup (this will also dismiss the modal on click):
            // '<button class="btn btn-secondary" data-dismiss="modal">Cancel</button>'
        ]
    };
    const settings = $.extend({}, defaults, options);

    // additional uncommon bootstrap options go into data attributes
    const dataAttributes = [];
    ['backdrop', 'keyboard', 'focus', 'show'].forEach(param => {
        if (settings[param] !== undefined) {
            dataAttributes.push(`data-${param}="${settings[param]}"`);
        }
    });

    let header = '<div class="modal-header">';
    if (settings.title) {
        header += `<div class="modal-title">${settings.title}</div>`;
    }
    if (settings.showCloseBtn) {
        header += '<button type="button" class="close btn btn-icon" data-dismiss="modal" aria-label="Close"><svg><use href="#close"></use></svg></button>';
    }
    header += '</div>';

    let idAttribute = '';
    if (settings.modalId) {
        idAttribute = `id="${settings.modalId}"`;
    }

    let footer = '';
    let footerBtns = '';
    for (let btn of settings.buttons) {
        footerBtns += btn;
    }
    if (footerBtns) {
        footer = `<div class="modal-footer">${footerBtns}</div>`;
    }

    // tabindex="-1" is necessary to allow the modal to close when ESC key is pressed.
    // https://stackoverflow.com/questions/12630156/how-do-you-enable-the-escape-key-close-functionality-in-a-twitter-bootstrap-moda
    const html = `<div class="modal fade ${settings.customClasses}" ${idAttribute}
        ${dataAttributes.join(' ')}
        role="dialog" aria-hidden="true" ${!settings.enableLayeredDrawers ? 'tabindex="-1"' : ''}>
            <div class="modal-dialog ${settings.centeredClass} ${settings.modalSizeClass}">
                <div class="modal-content">
                    ${header}
                    <div class="modal-body">
                        ${content}
                    </div>
                    ${footer}
                </div>
            </div>
        </div>`;

    return html;
}
/**
 * Creates a backdrop element and appends it to the body tag.
 * @param {string} customClass An optional class to apply to the backdrop.
 */
function createBackdrop(customClass) {
    var cls = customClass || '';
    var html = '<div class="backdrop ' + cls + '"></div>';
    $('body').append(html);
}

/**
 * Removes from the page the backdrop with the passed in class, or all backdrops if nothing is passed.
 * @param {string} customClass An optional class for selecting a backdrop to be removed. All will be removed if this is null.
 */
function removeBackdrop(customClass) {
    var selector = '.backdrop' + (customClass ? '.' + customClass : '');
    $('body').find(selector).remove();
}

/**
 * display element in viewport
 * @param {string} el  element in viewport
 * @param {string} offsetToTop  off set top position
 * @return {boolean} element view port
 */
function elementInViewport(el, offsetToTop) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
        el = el.offsetParent; // eslint-disable-line no-param-reassign
        top += el.offsetTop;
        left += el.offsetLeft;
    }

    if (typeof offsetToTop !== 'undefined') {
        top -= offsetToTop;
    }

    if (window.pageXOffset !== null) {
        return (
            top < (window.pageYOffset + window.innerHeight) &&
            left < (window.pageXOffset + window.innerWidth) &&
            (top + height) > window.pageYOffset &&
            (left + width) > window.pageXOffset
        );
    }

    if (document.compatMode === 'CSS1Compat') {
        return (
            top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
            left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
            (top + height) > window.document.documentElement.scrollTop &&
            (left + width) > window.document.documentElement.scrollLeft
        );
    }

    return false;
}

/**
 * append script to body tag
 * @param {string} src  url of the script to append
 * @param {function} callback optional callback to fire after load
 */
function loadScript(src, callback) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    document.getElementsByTagName('body')[0].appendChild(script);

    if (callback) {
        script.onload = callback;
    }
}

/**
 * load script to DOM if document ready state is complete or load after window load
 * @param {string} src  url of the script to append
 * @param {function} callback optional callback to fire after load
 */
function loadJsAfterWindowLoad(src, callback) {
    if (document.readyState === 'complete') {
        loadScript(src, callback);
    } else {
        window.addEventListener('load', function () {
            loadScript(src, callback);
        });
    }
}

/**
 * Inserts pasted text at cursor location in input or textarea elements.
 * If a range of text is selected in the input, that range will be replaced by the new text
 * If no text in the input is selected, the string will be inserted at the end
 * @param {selector} formInput - DOM element for the target form input
 * @param {string} str - string to insert into the form
 */
function insertAtInputCursor(formInput, str) {
    var input = formInput;
    var start = input.selectionStart;
    var end = input.selectionEnd;

    // Inserts str between selection start and end positions
    input.value = input.value.substring(0, start) + str + input.value.substring(end);
    // Update cursor position
    input.selectionStart = start + str.length;
    input.selectionEnd = input.selectionStart;
}

/**
 * Returns validity of date
 * @param {string} stageMessage The name of the stage we're going to.
 * @returns {string} Returns true if the day is within the month. Returns false if it is an invalid date.
 */
function getTimeAndHours(stageMessage) {
    var date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return stageMessage + ' ' + strTime;
}
// intentionally export loadJsAfterWindowLoad onto global scope
global.cscUtils = global.cscUtils || {};
global.cscUtils.loadJsAfterWindowLoad = loadJsAfterWindowLoad;

/**
 * Converts a string to Title Case.
 * @example
 * 'fRiEnDsHiP is MAGIC' -> 'Friendship Is Magic'
 * 'don't uppercase contractions' -> 'Don't Uppercase Contractions'
 * 'hyphenated-strings work too' -> 'Hyphenated-Strings Work Too'
 * @param {string} str The string to be title cased.
 * @returns {string} The original string in title case.
 */
function stringToTitleCase(str) {
    return str.replace(/[^\s/-]+/g, function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
}

/** Function to heapify a subtree rooted with node ndx
 * @param {Array} list - The array to heapify
 * @param {number} ndx - The index of the root of the subtree to heapify
 * @param {number} heapSize - The size of the heap
 * @param {string} key - The object key to find the highest numbers for
 */
function heapify(list, ndx, heapSize, key) {
    let largest = ndx;
    // eslint-disable-next-line no-mixed-operators
    const left = 2 * ndx + 1;
    // eslint-disable-next-line no-mixed-operators
    const right = 2 * ndx + 2;

    // Check if left child is larger than root
    if (left < heapSize && list[left][key] > list[largest][key]) {
        largest = left;
    }

    // Check if right child is larger than root
    if (right < heapSize && list[right][key] > list[largest][key]) {
        largest = right;
    }

    // If largest is not root
    if (largest !== ndx) {
        // Swap array[i] and array[largest]
        // eslint-disable-next-line no-param-reassign
        [list[ndx], list[largest]] = [list[largest], list[ndx]];
        // Recursively heapify the affected sub-tree
        heapify(list, largest, heapSize, key);
    }
}

/** Function to heapify the array into max heap
 * @param {Array} list - The array to heapify
 * @param {string} key - The object key to find the highest numbers for
 */
function heapifyMax(list, key) {
    const arrayLength = list.length;

    // Build max heap
    for (let ndx = Math.floor(arrayLength / 2) - 1; ndx >= 0; ndx--) {
        heapify(list, ndx, arrayLength, key);
    }

    for (let ndx = arrayLength - 1; ndx > 0; ndx--) {
        let firstListItem = list[0];
        // eslint-disable-next-line no-param-reassign
        list[0] = list[ndx];
        // eslint-disable-next-line no-param-reassign
        list[ndx] = firstListItem;

        heapify(list, 0, ndx, key);
    }
}

/** Function to find the n highest numbers in an array
 * @param {Array} list - The array to find the n highest numbers in
 * @param {number} n - The number of highest numbers to find
 * @param {string} key - The object key to find the highest numbers for
 * @returns {Array} The n highest numbers
 */
function findNHighest(list, n, key) {
    if (list.length < n) {
        // eslint-disable-next-line no-param-reassign
        n = list.length;
    }

    const listToUse = JSON.parse(JSON.stringify(list)); // Deep copy the list
    const result = [];

    // Build max heap
    heapifyMax(listToUse, key);

    for (let i = 0; i < n; i++) {
        // Extract max element from the max heap
        result.push(listToUse.pop());
    }

    return result;
}

/** Function to compare two objects for deep equality
 * @param {Object} obj1 - The first object to compare
 * @param {Object} obj2 - The second object to compare
 * @returns {boolean} Whether the two objects are deep equal
 */
function deepEqual(obj1, obj2) {
    return (obj1 && obj2 && typeof obj1 === 'object' && typeof obj2 === 'object') ?
      (Object.keys(obj1).length === Object.keys(obj2).length) &&
        Object.keys(obj1).reduce(function (isEqual, key) {
            return isEqual && deepEqual(obj1[key], obj2[key]);
        }, true) : (obj1 === obj2);
}

module.exports = {
    appendParamToURL: appendParamToURL,
    keyValueArray: keyValueArray,
    getParamsFromURL: getParamsFromURL,
    updateURLParam: updateURLParam,
    arrayIntersection: arrayIntersection,
    createModalMarkup: createModalMarkup,
    queryStringToObject: queryStringToObject,
    objectToQueryString: objectToQueryString,
    removeQueryParam: removeQueryParam,
    removeQueryParamFromCurrentUrl: removeQueryParamFromCurrentUrl,
    createBackdrop: createBackdrop,
    removeBackdrop: removeBackdrop,
    setUrlKeyValue: setUrlKeyValue,
    setUrlData: setUrlData,
    decodeQueryParam: decodeQueryParam,
    elementInViewport: elementInViewport,
    loadJsAfterWindowLoad: loadJsAfterWindowLoad,
    insertAtInputCursor: insertAtInputCursor,
    stringToTitleCase,
    getTimeAndHours: getTimeAndHours,
    findNHighest: findNHighest,
    deepEqual: deepEqual
};

'use strict';

var Cleave = require('cleave.js').default;
require('cleave.js/dist/addons/cleave-phone.i18n.js');
var countryPhoneCodes = {
    AT: '+43',
    BE: '+32',
    DE: '+49',
    ES: '+34',
    FI: '+358',
    FR: '+33',
    IE: '+353',
    IT: '+39',
    NL: '+31',
    GB: '+44'
};
module.exports = {
    handleCreditCardNumber: function (cardFieldSelector, cardTypeSelector) {
        var cleave = new Cleave(cardFieldSelector, {
            creditCard: true,
            creditCardStrictMode: true,
            onCreditCardTypeChanged: function (type) {
                var creditCardTypes = {
                    visa: 'Visa',
                    mastercard: 'Master Card',
                    amex: 'Amex',
                    discover: 'Discover',
                    maestro: 'Maestro',
                    unknown: 'Unknown'
                };

                var cardType = creditCardTypes[Object.keys(creditCardTypes).indexOf(type) > -1
                    ? type
                    : 'unknown'];

                $(cardTypeSelector).val(cardType);

                $('.card-number-wrapper').attr('data-type', type);
                if (type === 'visa' || type === 'mastercard' || type === 'discover') {
                    $('#securityCode').attr('maxlength', 3);
                    $('#securityCode').attr('minlength', 3);
                } else {
                    $('#securityCode').attr('maxlength', 4);
                    $('#securityCode').attr('minlength', (type === 'amex') ? 4 : 3);
                }

                /**
                * Update Credit Card Images
                */
                $('.payment-method-image .selected').addClass('d-none').removeClass('d-inline-block');
                $('.payment-method-image .unselected').removeClass('d-none').addClass('d-inline-block');

                $(`[data-card-type="${type}"] .selected`).removeClass('d-none').addClass('d-inline-block');
                $(`[data-card-type="${type}"] .unselected`).removeClass('d-inline-block').addClass('d-none');
            }
        });

        $(cardFieldSelector).data('cleave', cleave);
    },

    serializeData: function (form) {
        var serializedArray = form.serializeArray();

        serializedArray.forEach(function (item) {
            if (item.name.indexOf('cardNumber') > -1) {
                item.value = $('#cardNumber').data('cleave').getRawValue(); // eslint-disable-line
            }
        });

        return $.param(serializedArray);
    },

    formatPhoneField: function (field) {
        if (!field.dataset.cleaveEnabled) {
            var loc = window.digitalData ? window.digitalData.page.pageInfo.country : '';
            var cleave;  // eslint-disable-line no-unused-vars
            var settings;
            if (loc === 'US' || loc === 'CA') {
                settings = {
                    numericOnly: true,
                    blocks: [0, 3, 0, 3, 4],
                    delimiters: ['(', ')', ' ', '-']
                };
            } else if (countryPhoneCodes[loc]) {
                settings = {
                    phone: true,
                    phoneRegionCode: loc,
                    rawValue: countryPhoneCodes[loc]
                };
            } else {
                // loc not found in countryPhoneCodes array
                settings = {
                    numericOnly: true,
                    prefix: '+',
                    noImmediatePrefix: true
                };
            }
            cleave = new Cleave(field, settings);
            // ensure that we only apply cleave to this field once.
            field.dataset.cleaveEnabled = true; // eslint-disable-line no-param-reassign
        }
    },

    formatPhone: function () {
        var selectors = [
            '.shippingPhoneNumber',
            '.js-ups-contact-phone',
            '.upsSelectedAddressPhone',
            '.profilePhoneNumber',
            '.billingPhoneNumber',
            '#pbl_phone'
        ];
        var $phoneFields = $(selectors.join(', '));
        if ($phoneFields.length > 0) {
            $phoneFields.each(function (index, field) {
                module.exports.formatPhoneField(field);
            });
        }
    }
};
